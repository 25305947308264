<template>
  <div class="flex flex-col relative bg-image-error-header">
    <header class="container py-5 flex justify-between items-center">
      <div class="flex flex-col items-start">
        <h4 class="text-white">
DEVOTED STUDIOS
</h4>
        <!--        <span class="text-xs text-gray-400">Create Your Own Supergroup</span>-->
      </div>
      <div>
        <router-link v-if="!authenticated" to="/">
          <FormButton size="small" class="text-white">
Login
</FormButton>
        </router-link>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FormButton from '@/components/Form/FormButton.vue';

export default {
  name: 'LayoutErrorHeader',
  components: {
    FormButton,
  },
  computed: {
    ...mapGetters('auth', ['authenticated']),
  },
};
</script>
